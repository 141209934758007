<template>
    <v-container>
        <AddTask/>
        <TasksTable />
    </v-container>  
</template>

<script>
import TasksTable from '@/components/templates/TasksTable'
import AddTask from '@/components/molecules/AddTask'

export default {
    components: {
		TasksTable,
		AddTask
    }
}
</script>